import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="product"
export default class extends Controller {
  static targets = [ "loader" ]

  showLoader() {
    this.loaderTarget.classList.remove('hidden')
  }
}
