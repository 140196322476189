import { Controller } from "@hotwired/stimulus"
import NestedForm from 'stimulus-rails-nested-form/dist/stimulus-rails-nested-form.umd.js'

export default class extends NestedForm {
  static targets = ["dropdowns"]

  connect() {
    window.addEventListener('click', (event) => {
      if (!event.target.classList.contains('dropdown-button')) {
        this.closeAllDropdowns();
      }
    });
  }

  toggleDropdown(event) {
    const target = event.target;
    const dropdown = target.closest('td').querySelector('.dropdown');
    dropdown.classList.toggle('hidden');
  }

  closeAllDropdowns() {
    this.dropdownsTargets.forEach(dropdown => {
      dropdown.classList.add('hidden');
    });
  }

  vendorChange(event) {
    const emrFormWrapper = document.querySelector('#emrFormWrapper');
    const loader = document.querySelector('#vendorDetailsLoading');
    loader.classList.remove('hidden');
    if(event.target.value === '') {
      emrFormWrapper.classList.add('hidden');
      loader.classList.add('hidden');
      return;
    }
    const form = event.target.closest('form');
    form.requestSubmit();
    loader.classList.add('hidden');
  }

  vendorDetailsRender(event) {
    if (event.target.id === 'vendor-details') {
      this.displayEmrDetails();
    }
  }

  displayEmrDetails() {
    const emrFormWrapper = document.querySelector('#emrFormWrapper');
    emrFormWrapper.classList.remove('hidden');
  }
}