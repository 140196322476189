import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="purchase-order-invoice"
export default class extends Controller {
  toggleCheckbox(event) {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    const button = document.getElementById('mark-as-complete-button');
    const checked = Array.from(checkboxes).some(checkbox => checkbox.checked);
    if (checked) {
      button.classList.remove('hidden');
    } else {
      button.classList.add('hidden')
    }
  }
}
