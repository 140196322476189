import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="payment"
export default class extends Controller {
    static targets = [
        "emailWrapper",
        "emailTextField",
        "descriptionWrapper",
        "descriptionField",
        "amountWrapper",
        "amountField",
        "charterWrapper",
        "amount",
        "calculateButton",
        "processingFeeWrapper",
        "processingFee",
        "totalAmount",
        "totalAmountField",
        "submitButton"
    ]

  processPaymentLinkChange(event) {
        if (event.target.value === 'email') {
            this.resetAmounts();
            this.showCharterWrapper();
            this.showDescriptionWrapperAndClear();
            this.showAmountWrapper();
            this.showEmailWrapperAndUpdateRequired();
        } else if (event.target.value === 'qr_code') {
            this.resetAmounts();
            this.showCharterWrapper();
            this.showDescriptionWrapperAndClear();
            this.showAmountWrapper();
            this.hideEmailWrapper();
        } else {
            this.resetAmounts();
            this.hideCharterWrapper();
            this.hideDescriptionWrapperAndClear();
            this.hideAmountWrapper();
            this.hideEmailWrapper();
        }
  }

    showEmailWrapperAndUpdateRequired() {
        this.emailWrapperTarget.classList.remove('hidden');
        this.updateRequired(true);
    }

    hideEmailWrapper() {
        this.emailWrapperTarget.classList.add('hidden');
        this.updateRequired(false);
    }

    showAmountWrapper() {
        this.amountWrapperTarget.classList.remove('hidden');
    }

    hideAmountWrapper() {
        this.amountWrapperTarget.classList.add('hidden');
    }

    resetAmounts() {
        this.amountFieldTarget.value = '';
        this.amountTarget.innerText = '';
        this.processingFeeTarget.innerText = '';
        this.totalAmountTarget.innerText = '';
        this.totalAmountFieldTarget.value = '';
        this.calculateButtonTarget.classList.add('hidden');
        this.processingFeeWrapperTarget.classList.add('hidden');
        this.submitButtonTarget.classList.add('hidden');
    }

    hideCalculationButton() {
        this.calculateButtonTarget.classList.add('hidden');
    }

    showCharterWrapper() {
        this.charterWrapperTarget.classList.remove('hidden');
    }

    hideCharterWrapper() {
        this.charterWrapperTarget.classList.add('hidden');
    }

    showDescriptionWrapperAndClear() {
        this.descriptionWrapperTarget.classList.remove('hidden');
        this.descriptionFieldTarget.value = '';
    }

    hideDescriptionWrapperAndClear() {
        this.descriptionWrapperTarget.classList.add('hidden');
        this.descriptionFieldTarget.value = '';
    }

    showCalculationButtonAndResetFields(event) {
        if (this.amountFieldTarget.value === '') {
            this.resetAmounts();
            this.hideCalculationButton();
            return;
        }
        this.processingFeeWrapperTarget.classList.add('hidden');
        this.amountTarget.innerText = '';
        this.processingFeeTarget.innerText = '';
        this.totalAmountTarget.innerText = '';
        this.totalAmountFieldTarget.value = '';
        this.submitButtonTarget.classList.add('hidden');
        this.processingFeeWrapperTarget.classList.add('hidden');
        this.calculateButtonTarget.classList.remove('hidden');
    }

    calculateProcessingFee() {
        const amount = this.amountFieldTarget.value;
        this.amountTarget.innerText = amount;
        const processingFee = amount * 0.026;
        this.processingFeeTarget.innerText = processingFee.toFixed(2);
        const totalAmount = parseFloat(amount) + processingFee;
        this.totalAmountTarget.innerText = totalAmount.toFixed(2);
        this.totalAmountFieldTarget.value = totalAmount.toFixed(2);
        this.processingFeeWrapperTarget.classList.remove('hidden');
        this.submitButtonTarget.classList.remove('hidden');
    }

    updateRequired(option) {
        this.emailTextFieldTarget.required = option;
    }
}
