import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce"

export default class extends Controller {
    initialize() {
        this.submit = debounce(this.submit.bind(this), 300)
        //On turbo:frame-load, hide the loading spinner
        document.addEventListener('turbo:frame-load', () => {
            this.hideLoader();
        });
    }

    submit(_event) {
        this.showLoader()
        this.element.requestSubmit()
    }

  submitNonTextField() {
    this.showLoader()
    this.element.requestSubmit()
  }

  showLoader() {
        document.getElementById('results').innerHTML = ''
        document.getElementById('loader').classList.remove('hidden')
  }

    hideLoader() {
            document.getElementById('loader').classList.add('hidden')
    }
}
