import { Controller } from "@hotwired/stimulus"
import NestedForm from 'stimulus-rails-nested-form/dist/stimulus-rails-nested-form.umd.js'

// Connects to data-controller="emr"
export default class extends NestedForm {
    static targets = [
        "mapContainer",
        "mapContainerLoading"
    ]
    connect() {
        this.getGeoLocation();
    }

    getGeoLocation() {
        console.log('getting location')
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.showPosition);
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }

    showPosition = (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        this.displayMap(latitude, longitude);
    }

    filterVendorsByName(e) {
        if (!e.target.value) {
            this.clearSearch();
            return;
        }
        const searchTerm = e.target.value.toLowerCase();
        const vendors = this.element.querySelectorAll('.vendor-name');
        vendors.forEach(vendor => {
            const container = vendor.closest('.vendor-container');
            const vendorName = vendor.innerText.toLowerCase();
            if (vendorName.includes(searchTerm)) {
                container.classList.remove('hidden');
            } else {
                container.classList.add('hidden');
            }
        });

    }

    filterVendorsByCity(e) {
        if (!e.target.value) {
            this.clearSearch();
            return;
        }
        const searchTerm = e.target.value.toLowerCase();
        const vendors = this.element.querySelectorAll('.vendor-city');
        vendors.forEach(vendor => {
            const container = vendor.closest('.vendor-container');
            const vendorCity = vendor.innerText.toLowerCase();
            if (vendorCity.includes(searchTerm)) {
                container.classList.remove('hidden');
            } else {
                container.classList.add('hidden');
            }
        });
    }

    filterVendorsByDescription(e) {
        if (!e.target.value) {
            this.clearSearch();
            return;
        }
        const searchTerm = e.target.value.toLowerCase();
        const vendors = this.element.querySelectorAll('.vendor-description');
        vendors.forEach(vendor => {
            const container = vendor.closest('.vendor-container');
            const vendorDescription = vendor.innerText.toLowerCase();
            if (vendorDescription.includes(searchTerm)) {
                container.classList.remove('hidden');
            } else {
                container.classList.add('hidden');
            }
        });
    }

    clearSearch() {
        const vendors = document.querySelectorAll('.vendor-container');
        vendors.forEach(vendor => {
            vendor.classList.remove('hidden');
        });
    }

    showLocation = (e) => {
        const latitude = e.target.dataset.latitude;
        const longitude = e.target.dataset.longitude;
        this.displayMap(latitude, longitude);
    }

    displayMap(latitude, longitude) {
        this.mapContainerLoadingTarget?.classList?.add('hidden');
        const mapElement = this.mapContainerTarget;
        if (!latitude || !longitude) {
            mapElement.innerHTML = 'No location data available';
            return;
        }
        const map = new google.maps.Map(mapElement, {
            zoom: 17,
            center: { lat: parseFloat(latitude), lng: parseFloat(longitude) }
        });
    }
}
