import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="purchase-order-show"
export default class extends Controller {
  static targets = ["loading", "shipFormLoading", "bundledItemsContainer", "updateDetailStatusButton"];

  submit() {
    this.loadingTarget.classList.remove('hidden');
  }

  toggleBundleDropdown(e) {
    // Get the data-detail-id from the clicked element
    const detailId = e.target.getAttribute('data-detail-id');
    this.bundledItemsContainerTargets.forEach((bundledItemsContainer) => {
      if (bundledItemsContainer.getAttribute('data-detail-id') === detailId) {
        bundledItemsContainer.classList.toggle('hidden');
      }
    });
  }

  formChange() {
    document.querySelector('#purchase-order-submit').classList.remove('invisible');
  }

  submitPurchaseOrder() {
    this.shipFormLoadingTarget.classList.remove('hidden');
  }

  showFormSubmitButton() {
    document.querySelector('#form-submit-button').classList.remove('invisible');
  }

  updateShippingOption(e) {
    const address = e.target.selectedOptions[0].dataset.address;
    const city = e.target.selectedOptions[0].dataset.city;
    const state = e.target.selectedOptions[0].dataset.state;
    const zip = e.target.selectedOptions[0].dataset.zip;
    document.querySelector('#purchase_order_shipping_address').value = address;
    document.querySelector('#purchase_order_shipping_city').value = city;
    document.querySelector('#purchase_order_shipping_state').value = state;
    document.querySelector('#purchase_order_shipping_zip').value = zip;
    this.submitShippingForm(e);
  }

  submitShippingForm(event) {
    this.shipFormLoadingTarget.classList.remove('hidden');
    event.target.closest('form').submit();
  }

  async printPackingSlip(e) {
    const row = e.target.closest('tr');
    const printButton = row.querySelector('.print-packing-slip');
    const backordered = printButton.dataset.backordered ? true : false;
    const check = row.querySelector('.printed-check');
    const purchaseOrderId = row.dataset.purchaseOrderId;
    try {
      const res = await fetch(`/purchase_orders/${purchaseOrderId}/print_packing_slip`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
      });
        check.classList.remove('invisible');
    } catch (error) {
        console.error(error);
    }
    window.open(`/purchase_orders/${purchaseOrderId}/packing_slip?backordered=${backordered}`, '_blank');
  }

  checkAllShip(e) {
    document.querySelectorAll('.ship-checkbox').forEach((checkbox) => {
      checkbox.checked = e.target.checked;
    });
  }

  checkAllBackordered(e) {
    document.querySelectorAll('.backordered-checkbox').forEach((checkbox) => {
      checkbox.checked = e.target.checked;
    });
  }

  checkAllFulfilled(e) {
    document.querySelectorAll('.fulfilled-checkbox').forEach((checkbox) => {
      checkbox.checked = e.target.checked;
    });
  }

  shipBoxChecked() {
    const checkboxes = Array.from(document.querySelectorAll('.ship-checkbox'));
    const weights = checkboxes.filter((checkbox) => checkbox.checked).map((checkbox) => parseFloat(checkbox.closest('tr').querySelector('.weight').innerHTML));
    const totalWeight = weights.reduce((a, b) => a + b, 0).toFixed(2);
    if (checkboxes.some((checkbox) => checkbox.checked)) {
      document.querySelector('#ship-container').classList.remove('hidden');
    } else {
      document.querySelector('#ship-container').classList.add('hidden');
    }
    document.querySelector('#order-weight').value = totalWeight;
  }

  revalidateAddress(event) {
    const loader = document.querySelector('#revalidate-loader');
    loader.classList.remove('hidden');
    try {
      fetch("/purchase_orders/revalidate_address", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "text/vnd.turbo-stream.html",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({
          address: document.querySelector('#order-address').value,
          city: document.querySelector('#order-city').value,
          state: document.querySelector('#order-state').value,
          zip: document.querySelector('#order-zip').value,
          weight: document.querySelector('#order-weight').value,
          length: document.querySelector('#order-length').value,
          width: document.querySelector('#order-width').value,
          height: document.querySelector('#order-height').value,
          phone: document.querySelector('#order-phone').value
        })
      })
          .then(response => response.text())
          .then(html => {
            loader.classList.add('hidden');
            document.querySelector('#address-fields').innerHTML = html;
          });
    } catch (error) {
      console.error(error);
      loader.classList.add('hidden');
    }
  }

  receiveAll(e) {
    const purchase_order_detail_rows = document.querySelectorAll('.pod-row');
    purchase_order_detail_rows.forEach((row) => {
        const quantity = parseInt(row.querySelector('.quantity').innerHTML);
        const quantityReceived = row.querySelector('.quantity-received');
        if (quantityReceived) {
          quantityReceived.value = quantity;
        }
    });
    this.formChange();
  }
}
