import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto';

// Connects to data-controller="dashboard"
export default class extends Controller {
  connect() {
    const purchaseOrdersByTypeChart = document.getElementById('purchaseOrdersByTypeChart');
    const purchaseOrdersByStatusChart = document.getElementById('purchaseOrdersByStatusChart');
    const purchaseOrdersByCharterChart = document.getElementById('purchaseOrdersByCharterChart');
    const learningCenterEnrollmentsBySiteChart = document.getElementById('learningCenterEnrollmentsBySiteChart');
    const learningCenterEnrollmentsByGradeChart = document.getElementById('learningCenterEnrollmentsByGradeChart');
    new Chart(purchaseOrdersByTypeChart, {
      type: 'doughnut',
      data: {
        labels: JSON.parse(purchaseOrdersByTypeChart.dataset.labels),
        datasets: [{
          label: '# of orders',
          data: JSON.parse(purchaseOrdersByTypeChart.dataset.data),
          borderWidth: 1
        }]
      }
    });
    new Chart(purchaseOrdersByStatusChart, {
      type: 'doughnut',
      data: {
        labels: JSON.parse(purchaseOrdersByStatusChart.dataset.labels),
        datasets: [{
          label: '# of orders',
          data: JSON.parse(purchaseOrdersByStatusChart.dataset.data),
          borderWidth: 1
        }]
      }
    });
    new Chart(purchaseOrdersByCharterChart, {
      type: 'doughnut',
      data: {
        labels: JSON.parse(purchaseOrdersByCharterChart.dataset.labels),
        datasets: [{
          label: '# of orders',
          data: JSON.parse(purchaseOrdersByCharterChart.dataset.data),
          borderWidth: 1
        }]
      }
    });
    new Chart(learningCenterEnrollmentsBySiteChart, {
      type: 'bar',
      data: {
        labels: JSON.parse(learningCenterEnrollmentsBySiteChart.dataset.labels),
        datasets: [{
          label: '# of enrollments',
          data: JSON.parse(learningCenterEnrollmentsBySiteChart.dataset.data),
          borderWidth: 1,
          backgroundColor: [
            'rgba(24,187,49,0.6)',
          ]
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        },
        layout: {
          padding: {
            left: 0,
          }
        }
      }
    });
    new Chart(learningCenterEnrollmentsByGradeChart, {
      type: 'bar',
      data: {
        labels: JSON.parse(learningCenterEnrollmentsByGradeChart.dataset.labels),
        datasets: [{
          label: '# of enrollments',
          data: JSON.parse(learningCenterEnrollmentsByGradeChart.dataset.data),
          borderWidth: 1,
          backgroundColor: [
            'rgba(105,0,255,0.6)',
          ]
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        },
        layout: {
          padding: {
            left: 0,
          }
        }
      }
    });
  }
}
