import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropdowns"]

  connect() {
    window.addEventListener('click', (event) => {
      if (!event.target.classList.contains('dropdown-button')) {
        this.closeAllDropdowns();
      }
    });
  }

  toggleDropdown(event) {
    const target = event.target;
    const dropdown = target.closest('.dropdown-wrapper').querySelector('.dropdown');
    dropdown.classList.toggle('hidden');
  }

  closeAllDropdowns() {
    this.dropdownsTargets.forEach(dropdown => {
      dropdown.classList.add('hidden');
    });
  }
}