import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="submit-loading"
export default class extends Controller {
  static targets = ["submitButton", "loading"]
  submitForm() {
    //Turn the text in the submit button to a loading spinner
    this.submitButtonTarget.classList.add('hidden');
    this.loadingTarget.classList.remove('hidden');
  }
}