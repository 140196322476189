import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkout"
export default class extends Controller {
    static targets = ["removeCouponConfirm", "submitOrderButton", "loader"]
  removeCoupon() {
    this.removeCouponConfirmTarget.classList.remove("hidden")
  }

  submitOrder(event) {
    this.submitOrderButtonTarget.classList.add("hidden")
    this.loaderTarget.classList.remove("hidden")
  }

  validateLearningCenterTerms(event) {
      // Get all checkboxes
        const checkboxes = document.querySelectorAll('.learning-center-terms-checkbox');
        // If all are checked, enable the submit button
        let allChecked = true;
        checkboxes.forEach(checkbox => {
            if (!checkbox.checked) {
                allChecked = false;
            }
        });
        if (allChecked) {
            this.updateOrderSubmitButton(true);
        } else {
            this.updateOrderSubmitButton(false);
        }
  }

    updateOrderSubmitButton(isEnabled) {
        if (isEnabled) {
          console.log('enabled');
            this.submitOrderButtonTarget.classList.remove('mt-4', 'mb-8', 'w-full', 'rounded-md', 'bg-red-700', 'px-6', 'py-3', 'font-medium', 'text-white', 'cursor-not-allowed');
          this.submitOrderButtonTarget.classList.add('mt-4', 'mb-8', 'w-full', 'rounded-md', 'bg-indigo-500', 'hover:bg-indigo-700', 'px-6', 'py-3', 'font-medium', 'text-white', 'cursor-pointer');
          this.submitOrderButtonTarget.disabled = false;
          this.submitOrderButtonTarget.value = 'Place Order';
          this.submitOrderButtonTarget.innerText = 'Place Order';
        } else {
          this.submitOrderButtonTarget.classList.remove('mt-4', 'mb-8', 'w-full', 'rounded-md', 'bg-indigo-500', 'hover:bg-indigo-700', 'px-6', 'py-3', 'font-medium', 'text-white', 'cursor-pointer');
          this.submitOrderButtonTarget.classList.add('mt-4', 'mb-8', 'w-full', 'rounded-md', 'bg-red-700', 'px-6', 'py-3', 'font-medium', 'text-white', 'cursor-not-allowed');
          this.submitOrderButtonTarget.value = 'Please agree to all terms';
          this.submitOrderButtonTarget.innerText = 'Please agree to all terms';
          this.submitOrderButtonTarget.disabled = true;
        }
    }
}
