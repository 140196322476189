import NestedForm from 'stimulus-rails-nested-form/dist/stimulus-rails-nested-form.umd.js'
export default class extends NestedForm {
    static targets = ["maxGuests", "addGuestButton"]
    connect() {
        super.connect();
        this.checkMaxGuests();
    }

    remove(e) {
        super.remove(e)
        this.checkMaxGuests()
    }

    checkMaxGuests() {
        const maxGuests = parseInt(this.maxGuestsTarget.innerHTML)
        const guests = this.element.querySelectorAll('.nested-form-wrapper').length
        if (guests >= maxGuests) {
            this.addGuestButtonTarget.classList.add('hidden')
            this.addGuestButtonTarget.disabled = true
        } else {
            this.addGuestButtonTarget.classList.remove('hidden')
            this.addGuestButtonTarget.disabled = false
        }
    }
}
