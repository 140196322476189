import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import Choices from "choices.js";
import NestedForm from 'stimulus-rails-nested-form/dist/stimulus-rails-nested-form.umd.js'

// Connects to data-controller="vci"
export default class extends NestedForm {
  static targets = [
      "vciFormWrapper",
      "vendorMap",
      "billingFrequencyForm",
      "price",
      "total",
      "billingFrequencySelect",
      "datesSelectWrapper",
      "editOrder",
      "vendorLoading"
  ]

  connect() {
    const selectElements = document.querySelectorAll('.js-choice-select');
    selectElements.forEach(selectElement => {
      // create a new choices instance
      const choices = new Choices(selectElement, {
        allowHTML: false,
        searchPlaceholderValue: 'Enter Vendor Name',
        searchResultLimit: 10,
        itemSelectText: 'click to select',
        classNames: {
          containerInner: 'form-input',
        }
      });
    });
    const config = { attributes: true, childList: true, subtree: true };
    const formObserver = new MutationObserver(this.handleFormMutation.bind(this));
    formObserver.observe(this.vciFormWrapperTarget, config);
    this.displayMap();
    this.updateCalendars();
  }

  handleFormMutation(mutationsList) {
    if (!mutationsList.some(mutation => mutation.target === this.totalTarget)) {
      this.updateTotal();
    }
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        mutation.removedNodes?.forEach(node => {
          const selects = node.querySelectorAll('select');
          const inputs = node.querySelectorAll('input');
          const textAreas = node.querySelectorAll('textarea');
          selects.forEach(select => {
            select.removeAttribute('required');
          });
          inputs.forEach(input => {
            input.removeAttribute('required');
            input.removeAttribute('min');
          });
          textAreas.forEach(textArea => {
            textArea.removeAttribute('required');
          });
        });
      }
    }
  }

  vendorChange(event) {
    this.vendorLoadingTarget.classList.remove('invisible');
    this.addVendorLocationIdToForm(event.target.value);
    const form = event.target.closest('form');
    form.requestSubmit();
  }

  displayMap(event) {
    this.vendorLoadingTarget.classList.add('invisible');
    const vendorCoordinates = document.querySelector('#vendor-coordinates');
    if (!vendorCoordinates) {
      return;
    }
    const latitude = vendorCoordinates.dataset.latitude;
    const longitude = vendorCoordinates.dataset.longitude;
    const mapElement = this.vendorMapTarget;
    this.vciFormWrapperTarget.classList.remove('hidden');
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });

    if (!latitude || !longitude) {
      mapElement.innerHTML = 'No location data available';
      return;
    }
    const map = new google.maps.Map(mapElement, {
      zoom: 15,
      center: { lat: parseFloat(latitude), lng: parseFloat(longitude) }
    });
  }

  vciFormLoaded(event) {
    this.addVendorIdToForm();
  }

  addVendorLocationIdToForm(id) {
    const input = document.querySelector('#vci_batch_vendor_location_id');
    input.value = id;
  }

  updateTotal(event) {
    document.querySelector('#total').innerText = `$${this.calculateTotal().toFixed(2)}`;
  }

  calculateTotal() {
    let total = 0;
    this.priceTargets.forEach((price, index) => {
      const wrapper = price.closest('.nested-form-wrapper');
      // If wrapper style has display: none, it is hidden and don't include in total
      if (wrapper.style.display === 'none') {
        return;
      }
      total += parseFloat(price.value || 0);
    });
    return total;
  }

  editOrder() {
    this.editOrderTarget.classList.remove('hidden')
  }

  async toggleDates(event) {
    const schoolDatesDiv = document.getElementById('school-dates');
    const schoolDates = JSON.parse(schoolDatesDiv.dataset.schoolDates);
    const closestWrapper = event.target.closest('.nested-form-wrapper');
    const calendarWrapper = closestWrapper.querySelector('.calendar-wrapper');
    const calendar = closestWrapper.querySelector('.calendar');
    if(event.target.value === '') {
       calendar.value = '';
       calendarWrapper.classList.add('hidden');
       return;
    }
    calendarWrapper.classList.remove('hidden');
    const value = event.target.value;
    if (value === 'range') {
      const range = {
        from: schoolDates[0],
        to: schoolDates[schoolDates.length - 1]
      };
      this.updateCalendar(calendar, 'range', [range]);
    } else {
      this.updateCalendar(calendar, 'multiple', schoolDates);
    }
  }

  updateCalendars() {
    const schoolDatesDiv = document.getElementById('school-dates');
    const schoolDates = JSON.parse(schoolDatesDiv.dataset.schoolDates);
    const wrappers = document.querySelectorAll('.nested-form-wrapper');

    if (!wrappers) {
      return;
    }
    wrappers.forEach(wrapper => {
      const calendarType = wrapper.querySelector('.calendar-type');
      const calendar = wrapper.querySelector('.calendar');
      if (calendarType.value === 'range') {
        const range = {
          from: schoolDates[0],
          to: schoolDates[schoolDates.length - 1]
        };
        this.updateCalendar(calendar, 'range', [range]);
      } else {
        this.updateCalendar(calendar, 'multiple', schoolDates);
      }
    });

  }

  updateCalendar(calendar, type, availableDates) {
      calendar.classList.remove('hidden');
      flatpickr(calendar, {
        mode: type,
        dateFormat: "Y-m-d",
        enable: availableDates
      });
  }

  showFormSubmitButton() {
    console.log('test');
    document.querySelector('#form-submit-button')?.classList?.remove('invisible');
  }
}
