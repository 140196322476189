import { Application } from "@hotwired/stimulus"
import Autocomplete from 'stimulus-autocomplete'
import NestedForm from 'stimulus-rails-nested-form/dist/stimulus-rails-nested-form.umd.js'

const application = Application.start()
application.register('nested-form', NestedForm)
application.register('autocomplete', Autocomplete)

application.debug = false
window.Stimulus   = application

export { application }