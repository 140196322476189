// import Sentry from your framework SDK (e.g. @sentry/react) instead of @sentry/browser
import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: "https://803377c0930b689b437ca4d3ecfc472a@o4505947585773568.ingest.us.sentry.io/4507064665440256",

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
        new Sentry.Replay({
            // Additional SDK configuration goes in here, for example:
            maskAllText: false,
            blockAllMedia: false,
            maskAllInputs: false,
        }),
    ],
});