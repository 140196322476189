import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="purchase-order-tabs"
export default class extends Controller {
  swapActiveClass(event) {
    document.querySelectorAll('.detailsLink').forEach((link) => {
      link.classList = '';
      link.classList.add('inline-block', 'p-2', 'border-b-2', 'border-transparent', 'rounded-t-lg', 'hover:text-gray-600', 'hover:border-gray-400', 'font-bold', 'detailsLink');
    });
    event.currentTarget.classList = '';
    event.currentTarget.classList.add('inline-block', 'p-2', 'text-blue-600', 'border-b-2', 'border-blue-600', 'rounded-t-lg', 'active', 'font-bold', 'detailsLink');
  }
}
