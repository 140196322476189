import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="vendor-portal"
export default class extends Controller {
  toggleAmount(e) {
      const value = e.target.value;
      const row = e.target.closest('.po-row');
      const amountInput = row.querySelector('.amount-input');
      const amount = e.target.dataset.amount;
      if(value === '') {
          amountInput.value = '';
          amountInput.classList.add('invisible');
          amountInput.disabled = true;
          amountInput.required = false;
      } else if(value === 'invoice_partial') {
          amountInput.value = '';
          amountInput.classList.remove('invisible');
          amountInput.disabled = false;
          amountInput.required = true;
      } else {
          amountInput.value = amount;
          amountInput.classList.remove('invisible');
          amountInput.disabled = false;
          amountInput.required = true;
      }
      this.displaySubmitButton();
  }

    displaySubmitButton() {
      const submitButton = document.querySelector('#form-submit');
      const amountInputs = document.querySelectorAll('.amount-input');
      const anyVisible = Array.from(amountInputs).some(amountInput => {
          return !amountInput.classList.contains('invisible');});
        if(anyVisible) {
            submitButton.classList.remove('invisible');
        } else {
            submitButton.classList.add('invisible');
        }
    }

  acknowledgedByInput(e) {
    const submittButton = document.querySelector('#acknowledgedBySubmit');
    if(e.target.value === '') {
        submittButton.classList.add('form-input-disabled');
        submittButton.disabled = true;
    } else {
        submittButton.classList.remove('form-input-disabled');
        submittButton.classList.add('btn-indigo');
        submittButton.disabled = false;
    }
  }
}
