import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="order-note"
export default class extends Controller {
  showRequiresApproval(e) {
    const selectValue = e.target.value;
    const container = document.querySelector('.requires-approval-container');
    const checkbox = container.querySelector('input[type="checkbox"]');
    if (selectValue === '') {
      container.classList.add('hidden');
      checkbox.checked = false;
      checkbox.disabled = true;
    } else {
      container.classList.remove('hidden');
      checkbox.disabled = false;
    }
  }
}
