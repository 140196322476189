import { Controller } from "@hotwired/stimulus"
import Choices from 'choices.js'

// Connects to data-controller="learning-center-course"
export default class extends Controller {
    static targets = ["dropdowns", "choices", "loader"]

    connect() {
        window.addEventListener('click', (event) => {
            if (!event.target.classList.contains('dropdown-button')) {
                this.closeAllDropdowns();
            }
        });
        this.addChoices();
    }

    toggleDropdown(event) {
        const target = event.target;
        const dropdown = target.closest('td').querySelector('.dropdown');
        dropdown.classList.toggle('hidden');
    }

    closeAllDropdowns() {
        this.dropdownsTargets.forEach(dropdown => {
            dropdown.classList.add('hidden');
        });
    }

    addChoices() {
        this.choicesTargets.forEach((choice) => {
            new Choices(choice, {
                allowHTML: false,
                searchPlaceholderValue: 'Search...',
                classNames: {
                    containerInner: 'form-input'
                }
            });
        });
    }

    addToCart(event) {
        this.loaderTarget.classList.remove('hidden');
    }

    toggleActions(event) {
        const deviceDropdown = document.querySelector('#deviceDropdown');

        deviceDropdown.classList.toggle('hidden');
    }
}

