import NestedForm from 'stimulus-rails-nested-form/dist/stimulus-rails-nested-form.umd.js'
import Choices from "choices.js";

export default class extends NestedForm {
  static targets = ["loading", "form", "submitForm", "detailsLink"]

  connect() {
    const config = { attributes: true, childList: true, subtree: true };
    const formObserver = new MutationObserver(this.handleFormMutation.bind(this));
    formObserver.observe(this.formTarget, config);
  }

  handleFormMutation(mutationsList) {
    mutationsList.forEach(mutation => {
      // Example condition: Check if a specific class is added or removed
      if (mutation.type === 'childList' || mutation.type === 'attributes') {
        // Perform form change only if the mutation involves a specific class change
        if (mutation.target.classList.contains('purchase-order-details-table') || mutation.target.classList.contains('nested-form-wrapper')) {
          this.formChange();
        }
      }
    });
  }

  updateShippingOption(e) {
    const address = e.target.selectedOptions[0].dataset.address;
    const city = e.target.selectedOptions[0].dataset.city;
    const state = e.target.selectedOptions[0].dataset.state;
    const zip = e.target.selectedOptions[0].dataset.zip;
    document.querySelector('#purchase_order_shipping_address').value = address;
    document.querySelector('#purchase_order_shipping_city').value = city;
    document.querySelector('#purchase_order_shipping_state').value = state;
    document.querySelector('#purchase_order_shipping_zip').value = zip;
    this.formChange();
  }

  formChange() {
    document.querySelector('#recalculate').classList.remove('hidden');
    document.querySelector('#purchase-order-submit').classList.remove('hidden');
  }

  submitForm(event) {
      this.loadingTarget.classList.remove('hidden');
  }

  statusChange(event) {
    // If status is changed to cancelled
    if (event.target.value === '5') {
      const statusSelects = document.querySelectorAll('.order-detail-status-select');
      statusSelects.forEach((statusSelect) => {
        statusSelect.value = '3';
      });
    }
    // If status is changed to approved
    if (event.target.value === '3') {
      const statusSelects = document.querySelectorAll('.order-detail-status-select');
      statusSelects.forEach((statusSelect) => {
        statusSelect.value = '1';
      });
    }
    if (event.target.value === '9') {
      const statusSelects = document.querySelectorAll('.order-detail-status-select');
      statusSelects.forEach((statusSelect) => {
        statusSelect.value = '9';
      });
    }
    if (event.target.value === '2') {
      const statusSelects = document.querySelectorAll('.order-detail-status-select');
      statusSelects.forEach((statusSelect) => {
        statusSelect.value = '4';
      });
    }
    if (event.target.value === '15') {
      const statusSelects = document.querySelectorAll('.order-detail-status-select');
      statusSelects.forEach((statusSelect) => {
        statusSelect.value = '15';
      });
    }
  }

  markAllPaid() {
    const purchase_order_detail_rows = document.querySelectorAll('.pod-row');
    purchase_order_detail_rows.forEach((row) => {
      const quantity = parseInt(row.querySelector('.quantity').value);
      const quantityPaid = row.querySelector('.quantity-paid');
      quantityPaid.value = quantity;
    });
    this.formChange();
  }
}
